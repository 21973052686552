// About application 

.about-app {
    overflow: hidden;
    &__wrapper {
        display: flex;
        flex-direction: column-reverse;

        @media only screen and (min-width: $md) {
            display: flex;
            flex-direction: row;
        }
    }

    &__text {
        padding: rem(48) 0;
        box-sizing: border-box;
        max-width: rem(400);

        @media only screen and (min-width: $md) {
            padding: rem(110) 0;
            margin-right: 10%;
        }
    }

    &__text p {
        font-size: rem(14);
    }

    &__text p:not(:last-child) {
        margin-bottom: rem(24);
    }

    &__image {
        position: relative;
        width: 100%;
    }

    &__image picture,
    &__image img {
        height: rem(400);
        width: calc(100% + 64px);
        max-width: none;
        object-fit: cover;
        object-position: right center;
        margin-left: rem(-32);

        @media only screen and (min-width: $md) {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 70vw;
            margin-left: 0;
        }
    }
}