// Colors 

// Primary 
$primary-color: #42c3a7;
$primary-dark-color: #27A289;
$secondary-color: #182748;
$dark-color: #f9fafc;
$alert-color: #EF464C;

// Font color 
$font-color: $secondary-color;