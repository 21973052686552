// Form 

.form {
    position: relative;
    background-color: #FFFFFF;
    box-shadow: 0px 10px 56px rgba(0, 0, 0, 0.13);
    z-index: 2;
    width: calc(100% + 64px);
    margin-left: rem(-32);

    @media only screen and (min-width: $sm) {
        width: 100%;
        margin-left: 0;
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 rem(24);
        border-bottom: 1px solid #DFDFDF;

        @media only screen and (min-width: $sm) {
            padding: 0 rem(48);
        }
    }

    &__header-text {
        padding-top: rem(48);
        padding-bottom: rem(48);
        
    }

    &__header-title {
        font-size: rem(18);
        line-height: 1.2;
        margin-bottom: rem(16);

        @media only screen and (min-width: $md) {
            font-size: rem(32);
        }
    }

    &__header-note {
        font-size: rem(12);
        line-height: 1.2;
        color: $primary-color;

        @media only screen and (min-width: $md) {
            font-size: rem(13);
        }
    }

    &__header-icon {
        display: none;
        padding-top: rem(32);
        padding-bottom: rem(32);
        padding-left: rem(48);
        margin-left: rem(48);
        border-left: 1px solid #DFDFDF;

        @media only screen and (min-width: $sm) {
            display: block;
        }
    }

    &__header-icon svg {
        flex-shrink: 0;
    }

    &__body {
        padding: rem(32) rem(24);

        @media only screen and (min-width: $sm) {
            padding: rem(48) rem(48);
        }
    }

    &__row {

        @media only screen and (min-width: $lg) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__input {
        position: relative;
        width: 100%;
        margin-bottom: rem(24);
    }

    &__input--50 {

        @media only screen and (min-width: $lg) {
            width: calc(50% - 16px);
        }
    }

    &__legend {
        margin-bottom: rem(4);
    }

    &__legend label {
        font-size: rem(13);
        font-weight: 400;
    }

    &__legend label span {
        display: inline-block;
        font-size: rem(14);
        font-weight: 400;
        color: $primary-color;
        margin-left: rem(2);
    }

    &__input input {
        font-size: rem(14);
        width: 100%;
        box-sizing: border-box;
        height: rem(47);
        padding: 0 rem(16);
        border: 1px solid #E4E4E4;
        background-color: #F9FAFC;
        outline: none;
    }

    &__input input::placeholder {
        font-family: inherit;
        font-size: rem(14);
        font-weight: 400;
        color: #797979;
    }

    &__input input:focus {
        border-color: $primary-color;
        outline: none;
    }

    &__input.error input{
        color: $alert-color;
        border-color: $alert-color;
    }

    &__input textarea {
        font-size: rem(14);
        width: 100%;
        padding: rem(16);
        box-sizing: border-box;
        border: 1px solid #E4E4E4;
        background-color: #F9FAFC;
    }

    &__input textarea::placeholder {
        font-family: inherit;
        font-size: rem(14);
        font-weight: 400;
        color: #797979;
    }

    &__input textarea:focus {
        border-color: $primary-color;
        outline: none;
    }

    &__error {
        display: none;
        position: absolute;
        top: calc(100% + 8px);
        left: 0;
        font-size: rem(10);
        line-height: 1.3;
        color: $alert-color;
    }

    &__input.error label span {
        color: $alert-color;
    }

    &__cta {
        margin-top: rem(16);
    }

    &__cta .button {
        width: 100%;
    }
}