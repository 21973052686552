// Navigation 

.navigation {
    background-color: $secondary-color;

    .container {
        margin: 0 auto;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: rem(80);

        @media only screen and (min-width: $lg) {
            height: rem(104);
        }
    }

    &__menu {
        position: absolute;
        top: rem(80);
        left: -100%;
        height: calc(100vh - 80px);
        background-color: $secondary-color;
        max-width: 100%;
        padding: rem(48);
        box-sizing: border-box;
        z-index: 99999;

        @media only screen and (min-width: $lg) {
            display: block;
            position: static;
            height: auto;
            background-color: transparent;
            padding: 0;
            right: 0;
            z-index: 1;
        }
    }

    &__menu.opened {
        left: 0;
        transition: all 0.8s cubic-bezier(.62,.56,.21,.97);

        @media only screen and (min-width: $lg) {
            transition: none;
        }
    }

    &__menu ul {
        padding: 0;
        margin: 0;
        list-style: none;
        text-align: center;

        @media only screen and (min-width: $lg) {
            display: flex;
            align-items: center;
            text-align: left;
        }
    }

    &__menu-item {
        line-height: 1;
    }

    &__menu-item:not(:last-child) {
            margin-bottom: rem(16);

        @media only screen and (min-width: $lg) {
            margin-bottom: 0;
        }
    }

    &__menu-item .button--secondary {
        color: #FFFFFF;
    }

    &__menu-item .button--secondary:hover {
        color: $secondary-color;
        background-color: #FFFFFF;
    }

    &__menu-item--link a {
        color: #FFFFFF;
        text-decoration: none;

        @media only screen and (min-width: $lg) {
            margin-right: rem(53);
        }
    }

    &__menu-item--link a:hover,
    &__menu-item--link a:focus {
        color: $primary-color;
    }

    &__menu-item--button-first {
        margin-top: rem(32);

        @media only screen and (min-width: $lg) {
            margin-top: 0;
        }
    }

    &__menu-item--button:not(:last-child) {

        @media only screen and (min-width: $lg) {
            margin-right: rem(18);
        }
    }

    // Hamburger button wrapper 
    &__menu-button {
        display: flex;
        align-items: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        padding: 0;

        @media only screen and (min-width: $lg) {
            display: none;
        }
    }

    &__menu-button-title {
        display: block;
        font-size: rem(14);
        color: #FFFFFF;
        margin-left: rem(8);
    }
}

// Hamburger menu 
  .line {
    fill: none;
    stroke: #FFFFFF;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 3;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 3;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 3;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 3;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 3;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 3;
  }
  