// Contact 

.contact {
    
    background-color: $dark-color;

    &__wrapper {
        padding-top: rem(78);
        padding-bottom: rem(78);

        @media only screen and (min-width: $md) {
            display: flex;
            padding-top: rem(110);
            padding-bottom: rem(110);
        }
    }   

    // First box 
    &__box {
        margin-bottom: rem(48);

        @media only screen and (min-width: $md) {
            margin-bottom: 0;
        }
    }

    &__box-image {
        position: relative;
        margin-bottom: rem(52);
    }

    &__box-image picture,
    &__box-image img {
        object-fit: cover;
    }

    &__box-footer {
        position: absolute;
        left: rem(-16);
        bottom: rem(-20);
        z-index: 2;
    }

    &__box-phone,
    &__box-email {
        display: flex;
        align-items: center;
        background-color: #FFFFFF;
        height: rem(40);
        padding: 0 rem(16);
        border-radius: 5px;
        text-decoration: none;
        box-shadow: 0px 10px 56px rgba(0, 0, 0, 0.13);
    }

    &__box-phone span,
    &__box-email span {
        font-size: rem(13);
        font-weight: 600;
        color: $font-color;
    }

    &__box-phone svg,
    &__box-email svg {
        margin-right: rem(8);
        color: $alert-color;
    }

    &__box-phone {
        margin-right: rem(16);
        margin-bottom: rem(8);
    }

    // Box phone and email hover 
    &__box-phone:hover,
    &__box-email:hover {
        background-color: $primary-color;

        svg {
            color: $secondary-color;
        }

        span {
            color: #FFFFFF;
        }
    }

    &__box-info {
        padding-right: rem(48);
    }

    &__box-info svg {
        margin-bottom: rem(24);
    }

    &__box-info-company {
        font-size: rem(14);
    }

    &__box-info-company strong {
        font-weight: 700;
    }

    // Last box | Form 
    &__form {
        @media only screen and (min-width: $md) {
            max-width: 100%;
            width: 60%;
            flex-shrink: 0;
        }
    }

}