// Footer 

.footer {

    border-top: 1px solid #DFDFDF;
    padding: rem(24) 0;

    p {
        font-size: rem(14);
        font-weight: 400;
        text-align: center
    }
}