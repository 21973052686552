// News 

.news {

    padding-top: rem(78);
    padding-bottom: rem(78);
    background-color: $secondary-color;

    @media only screen and (min-width: $md) {
        padding-top: rem(90);
        padding-bottom: rem(112);
    }

    .heading,
    .description {
        color: #FFFFFF;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        margin-top: rem(48);

        @media only screen and (min-width: $md) {
            margin-top: rem(83);
        }
    }

    &__card {
        background-color: #FFFFFF;
        border-radius: 14px;
        text-decoration: none;
        overflow: hidden;
        padding-bottom: rem(24);
        box-sizing: border-box;
        margin: rem(8);

        @media only screen and (min-width: $sm) {
            width: calc(50% - 16px);
        }

        @media only screen and (min-width: $md) {
            width: calc(33.333% - 16px);
        }

        @media only screen and (min-width: $lg) {
            width: calc(25% - 16px);
        }
    }

    &__card-image {
        height: rem(137);
        overflow: hidden;
        margin-bottom: rem(21);
    }

    &__card-image picture,
    &__card-image img {
        object-fit: cover;
        object-position: center center;
    }

    &__card-text {
        padding: 0 rem(24);
    }

    &__card-text h1,
    &__card-text h2,
    &__card-text h3,
    &__card-text h4,
    &__card-text h5,
    &__card-text h6 {
        font-size: rem(20);
        font-weight: 600;
        color: $secondary-color;
        text-align: center;
        line-height: 1.4;
        margin-bottom: rem(32);
    }

    &__card-text p {
        font-size: rem(12);
        font-weight: 400;
        color: $secondary-color;
    }

    &__card-footer {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: rem(21);
        padding-top: rem(21);
        padding-left: rem(24);
        padding-right: rem(24);
    }

    &__card-footer::before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: rem(24);
        right: rem(24);
        height: 1px;
        border-top: 1px solid #DFDFDF;
    }

    &__card-time {
        font-size: rem(12);
        font-weight: 400;
        color: $secondary-color;
    }

    // Hover 
    &__card:hover .button {
        background-color: $primary-dark-color;
        border: 1px solid $primary-dark-color;
    }
    
}