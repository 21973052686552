// Button 

.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-family: 'Be Vietnam Pro', sans-serif;
    font-size: rem(14);
    font-weight: 400;
    height: rem(48);
    text-decoration: none;
    border: 0;
    background-color: transparent;
    padding: 0 rem(48);
    outline: 0;
    border-radius: 48px;
    white-space: nowrap;
    box-sizing: border-box;
    cursor: pointer;
}

.button--primary {
    color: #FFFFFF;
    background-color: $primary-color;
    border: 1px solid $primary-color;
}

.button--primary:hover,
.button--primary:focus {
    background-color: $primary-dark-color;
    border: 1px solid $primary-dark-color;
}

.button--secondary {
    color: $secondary-color;
    background-color: transparent;
    border: 1px solid $primary-color;
}

.button--secondary:hover,
.button--secondary:focus {
    color: #FFFFFF;
    background-color: $secondary-color;
    border: 1px solid $secondary-color; 
}