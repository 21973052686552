// Partners 

.partner {
    padding: rem(78) 0;

    &__wrapper {
        margin-top: rem(48);
    }

    &__box {
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
        height: rem(80);
        padding: 0 rem(24);
        border-radius: 10px;
        border: 1px solid #E5E0E0;
    }


    &__box picture,
    &__box img {
        max-height: rem(45);
        width: rem(300);
        max-width: 100%;
        object-fit: contain;
        object-position: center center;
    }

    .swiper-wrapper {
        padding-bottom: rem(80);
    }

    .swiper-pagination {
        top: auto !important;
        bottom: 0;
    }

    .swiper-pagination-progressbar {
        background-color: #eeeeee !important;
    }

    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background-color: $primary-color !important;
    }
}