// Hero 

.hero {
    position: relative;
    overflow: hidden;
    height: rem(900);

    @media only screen and (min-width: $md) {
        height: 100%;
    }

    &__gradient {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 2;

        @media only screen and (min-width: $md) {
            display: block;
        }
    }

    &__wrapper {

        @media only screen and (min-width: $md) {
            display: flex;
        }
    }

    &__box {
        position: relative;
        width: 100%;
        height: rem(300);
        overflow: hidden;
        box-sizing: content-box;

        @media only screen and (min-width: $md) {
            width: 33.333%;
            height: 100%;
        }
    }

    &__box:nth-child(2) {

        @media only screen and (min-width: $md) {
            border-left: 4px solid #FFFFFF;
            border-right: 4px solid #FFFFFF;
        }
    }

    &__img--desktop {
        display: none;

        @media only screen and (min-width: $md) {
            display: block;
        }
    }

    &__img picture,
    &__img img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center center;
        -webkit-user-select: none; /* Safari */
        -ms-user-select: none; /* IE 10 and IE 11 */
        user-select: none; /* Standard syntax */
    }

    &__img--mob {
        height: rem(300);

        @media only screen and (min-width: $md) {
            display: none;
        }
    }

    &__img--mob picture, 
    &__img--mob img {
        height: rem(300);
        width: 100%;
        object-fit: cover;
        object-position: center center;

        @media only screen and (min-width: $md) {
            height: 100%;
        }
    }

    &__title {
        font-size: rem(24);
        font-weight: 600;
        color: #FFFFFF;
        line-height: 1;
        position: absolute;
        bottom: 15%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 3;

        @media only screen and (min-width: $md) {
            font-size: 3vw;
        }
    }

    &__box-gradient {
        position: absolute;
        left: 0;
        bottom: 0%;
        z-index: 2;

        @media only screen and (min-width: $xs) {
            bottom: -10%;
        }

        @media only screen and (min-width: $sm) {
            bottom: -30%;
        }

        @media only screen and (min-width: $md) {
            display: none;
        }
    }
}

// Hero v2 

.hero--v2 {
    height: auto;
    background-color: $primary-color;
    padding: rem(78) 0;

    @media only screen and (min-width: $md) {
        padding: rem(100) 0;
    }

    .heading {
        color: #FFFFFF;
    }
}