

// Selection 

*::selection {
    color: #FFFFFF;
    background-color: $secondary-color;
  }

// Default style 
html {
    box-sizing: border-box;
    scroll-behavior: smooth;
}

*, *:before, *:after {
    box-sizing: inherit;
}

* {
    margin: 0;
}

html, body {
    height: 100%;
}

body {
    font-family: 'Be Vietnam Pro', sans-serif;
    font-size: 1rem;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    line-height: 2;
    text-align: left;
    color: $font-color;
}

img, picture, video, canvas, svg {
    display: block;
    max-width: 100%;
}

input, button, textarea, select {
    font: inherit;
}

p, h1, h2, h3, h4, h5, h6 {
    overflow-wrap: break-word;
}

p {
    overflow-wrap: break-word;
    hyphens: auto;
}

.heading {
    font-size: rem(28);
    font-weight: 600;
    margin-bottom: rem(24);
    text-align: center;

    @media only screen and (min-width: $md) {
        font-size: rem(40);
    }
}

.description {
    font-size: rem(14);
    font-weight: 400;
    text-align: center;
    max-width: rem(458);
    margin: 0 auto;
    line-height: 1.8;
}

// Container 
.container {
    box-sizing: content-box;
    padding-left: rem(32);
    padding-right: rem(32);

    @media only screen and (min-width: $md) {
        padding-left: rem(40);
        padding-right: rem(40);
        margin: 0 auto;
        max-width: rem(1200);
    }
}


