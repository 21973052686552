// Unique selling proposition

.usp {
    background-color: $dark-color;
    padding-top: rem(78);
    padding-bottom: rem(78);

    @media only screen and (min-width: $md) {
        padding-bottom: rem(130);
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 32px);
        margin-left: rem(-16);
        margin-top: rem(48);
    }
    
    &__box {
      
        text-align: center;
        width: 100%;
        padding: rem(24) rem(32);
        margin: rem(16);
        background-color: #FFFFFF;
        border-radius: 14px;
        box-sizing: border-box;
        box-shadow: 0px 10px 56px rgba(0, 0, 0, 0.13);
    
        @media only screen and (min-width: $sm) {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: left;
            width: calc(50% - 32px);
            height: rem(120);
            padding: 0 rem(32);
        }
    
    
        @media only screen and (min-width: $md) {
            width: calc(33.333% - 32px);
        }
    
        @media only screen and (min-width: $lg) {
            width: calc(25% - 32px);
        }
    }
    
    &__box svg {
        color: $alert-color;
        flex-shrink: 0;
        margin: 0 auto rem(12) auto;
 
        @media only screen and (min-width: $sm) {
            margin: 0 rem(24) 0 0;

        }
    }
    
    &__box-text {
        display: block;
        font-size: rem(13);
        line-height: 1.4;
    
        @media only screen and (min-width: $lg) {
            font-size: rem(16);
        }
    }
    
    // Hover state 
    &__box:hover {
        color: #FFFFFF;
        background-color: $primary-color;
    
        svg {
            color: $secondary-color;
        }
    }
}
