// Reviews 

.review {
    background-color: #FFFFFF;
    padding-top: rem(78);
    padding-bottom: rem(78);

    @media only screen and (min-width: $md) {
        padding-bottom: rem(130);
    }

    &__wrapper {
        margin-top: rem(48);

        @media only screen and (min-width: $md) {
            margin-top: rem(80);
        }
    }

    &__box {
        max-width: 100%;
    }

    &__rating {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: rem(28);
    }

    &__rating svg:not(:last-child) {
        margin-right: rem(8);
    }

    &__message {
        font-size: rem(14);
        font-weight: 400;
        text-align: center;
    }

    &__name {
        display: block;
        font-size: rem(14);
        font-weight: 600;  
        text-align: center;
        border-top: 1px solid #DFDFDF;
        max-width: rem(200);
        margin: rem(16) auto 0 auto;
        padding-top: rem(16);
    }

    .swiper-wrapper {
        padding-bottom: rem(80);
    }

    .swiper-pagination {
        top: auto !important;
        bottom: 0;
    }

    .swiper-pagination-progressbar {
        background-color: #eeeeee !important;
    }

    .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
        background-color: $primary-color !important;
    }
}